import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import generalCss from '../../styles/generalCss'
import LinkComponent from '../GatsbyComponents/LinkComponent'
import PrimaryButton from '../GeneralComponents/Buttons/PrimaryButton'
import LogoSvg from '../SVGs/Logo'
import Routes from '../../utilities/Routes'
import MenuComponent from '../GeneralComponents/HoverMenuComponent'
import {
    usePopupState,
    bindHover
} from 'material-ui-popup-state/hooks'
import clsx from 'clsx'
import RightIcon from '@material-ui/icons/ChevronRight'
import RightArrow from '@material-ui/icons/ArrowForward'
import EmailIcon from '@material-ui/icons/Email'
import CallIcon from '@material-ui/icons/Call'
import LinkButton from '../GeneralComponents/Buttons/LinkButton'
import CloseIcon from '@material-ui/icons/Close'
import IconButtonComponent from '../GeneralComponents/IconButtonComponent'
const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: generalCss.appMaxWidth,
        margin: 'auto',
        width: '100%'
    },
    links: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: "600",
        color: "#1D2F43",
        '&:hover': {
            color: theme.palette.text.secondary,
        }
    },
    activeLink: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: "600",
        color: theme.palette.text.secondary,
    },
    menuDropDown: {
        backgroundColor: 'rgba(255,255,255,0.97)',
        width: '100vw',
        // height: '100vh',
        margin: "0px",
        padding: '0px',
        // boxShadow: "0px 10px 20px -1px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%)",
        top: "140px !important",
        left: '0px !important',
        maxWidth: "100vw",
        // maxHeight: 'calc(100vh - 164px)',
        borderRadius: '0px'
    },
    menu: { maxWidth: generalCss.appMaxWidth, margin: 'auto' },
    menuContainer: {
        padding: "30px 2rem 30px"
    },
    links: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        color: theme.palette.text.primary,
        fontWeight: '600',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.text.secondary
        }
    },
    links: {
        fontSize: '0.875rem',
        lineHeight: '1.3rem',
        color: theme.palette.text.primary,
        fontWeight: '600',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.text.secondary
        }
    },
    linksSmall: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        color: theme.palette.text.primary,
        fontWeight: '600',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.text.secondary
        }
    },
    linksSmallLinks: {
        fontSize: '0.8rem',
        lineHeight: '1.2rem',
        color: theme.palette.text.primary,
        fontWeight: '600',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.text.secondary
        }
    },
    linksSmallButton: {
        fontSize: '0.8rem',
        lineHeight: '1.2rem',
        color: theme.palette.text.secondary,
        fontWeight: '600',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.text.secondary
        }
    },
    linksTitle: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        paddingBottom: "5px",
        paddingRight: "60px"
    }
}))

export default function AppBarWeb({ appBarData = [], contactData = {} }) {
    const classes = useStyles()
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'appBar',
    })
    return (
        <>
            <div >
                <Grid container direction='column-reverse'>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <div {...bindHover(popupState)} style={{ padding: "20px 2rem" }}  >
                                <Grid container style={{ flexWrap: 'nowrap' }} spacing={3} alignItems='center'>
                                    <Grid item>
                                        <a href={'/'}>
                                            <LogoSvg />
                                        </a>
                                    </Grid>
                                    <Grid item style={{ flex: 1 }}>
                                        <Grid container justifyContent='space-evenly' alignItems='center'>
                                            {
                                                appBarData.filter(item => item.showInAppBar).map(item => {
                                                    return <Grid item key={item.id}>
                                                        <LinkComponent path={item.titleRoute} className={classes.links} activeClassName={classes.activeLink}>
                                                            {item.title}
                                                        </LinkComponent>
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <PrimaryButton href={Routes.contact}>
                                            Contact Us
                                        </PrimaryButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    {
                        (!!contactData.contactEmail || !!contactData.contactPhone1 || !!contactData.contactPhone2) &&
                        <Grid item xs={12} style={{
                            // borderBottom: "1px solid #c4c4c4",
                            backgroundColor: "#D1E1FF"
                        }}>
                            <div className={classes.root}>
                                <div style={{ padding: "10px 2rem" }}>
                                    <Grid container alignItems='center' justifyContent='flex-end' spacing={2}>
                                        {
                                            !!contactData.contactEmail &&
                                            <Grid item>
                                                <a href={`mailto:${contactData.contactEmail}`} className={classes.links}>
                                                    <Grid container spacing={1} alignItems='center' style={{ flexWrap: 'nowrap' }}>
                                                        <Grid item style={{ display: 'flex' }}><EmailIcon style={{ fontSize: '1rem' }} color='primary' /></Grid>
                                                        <Grid item><Typography className={classes.linksSmall}>{contactData.contactEmail}</Typography></Grid>
                                                    </Grid>
                                                </a>
                                            </Grid>
                                        }
                                        {
                                            (!!contactData.contactPhone1 || !!contactData.contactPhone2) &&
                                            <Grid item >
                                                <Grid container spacing={1} alignItems='center' style={{ flexWrap: 'nowrap' }} >
                                                    <Grid item style={{ display: 'flex' }}><CallIcon style={{ fontSize: '1rem' }} color='primary' /></Grid>
                                                    <Grid item>
                                                        <span>
                                                            {
                                                                !!contactData.contactPhone1 &&
                                                                <span>
                                                                    <a href={`tel:${contactData.contactPhone1}`} className={classes.links}>
                                                                        <span className={classes.linksSmall}>{contactData.contactPhone1}</span>
                                                                    </a>
                                                                </span>
                                                            }
                                                            {
                                                                !!contactData.contactPhone1 && !!contactData.contactPhone2 &&
                                                                <span >
                                                                    <span className={classes.linksSmall} style={{ whiteSpace: 'break-spaces' }}>  •  </span>
                                                                </span>
                                                            }
                                                            {
                                                                !!contactData.contactPhone2 &&
                                                                <span >
                                                                    <a href={`tel:${contactData.contactPhone2}`} className={classes.links}>
                                                                        <span className={classes.linksSmall}>{contactData.contactPhone2}</span>
                                                                    </a>
                                                                </span>
                                                            }
                                                            {
                                                                !!contactData.contactPhone2 &&
                                                                <span >
                                                                    <span className={classes.linksSmall} style={{ whiteSpace: 'break-spaces' }}>  •  </span>
                                                                </span>
                                                            }
                                                            <span>
                                                                <a href={`tel:+1 (224) 633-9291`} className={classes.links}>
                                                                    <span className={classes.linksSmall}>+1 (224) 633-9291</span>
                                                                </a>
                                                            </span>
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </div>

                            </div>
                        </Grid>
                    }
                </Grid>
            </div>
            <MenuComponent menuPaperClassName={classes.menuDropDown} popupState={popupState}>
                <div className={classes.menu}>
                    <div className={classes.menuContainer}>
                        <Grid container justifyContent='space-between'>
                            {
                                appBarData.filter(item => item.showInMenu).map(list => {
                                    return <Grid item key={list.id} xs={list.menuWidth}>
                                        <div style={{ paddingBottom: "2rem" }}>
                                            <LinkComponent path={list.titleRoute} activeClassName={classes.activeLink} className={clsx(classes.links, classes.linksTitle)}>
                                                {list.title}
                                            </LinkComponent>
                                        </div>
                                        <div>
                                            <Grid container spacing={2}>
                                                {
                                                    list.list.map(item => {
                                                        return <Grid onClick={popupState.close} key={item.id} item xs={12}>
                                                            <LinkComponent appendPath={false} path={item.slug} activeClassName={classes.activeLink} className={classes.links}>
                                                                <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
                                                                    <Grid item style={{ display: 'flex' }}>
                                                                        <RightIcon color='primary' style={{ fontSize: '1rem' }} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography className={classes.linksSmallLinks}>{item.title}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </LinkComponent>
                                                        </Grid>
                                                    })
                                                }
                                                {
                                                    list.showButton &&
                                                    <Grid item xs={12}>
                                                        <div>
                                                            <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
                                                                <Grid item style={{ display: 'flex', visibility: 'hidden' }}>
                                                                    <RightIcon color='primary' style={{ fontSize: '1rem' }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <LinkButton href={list.buttonRoute}>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item className={classes.linksSmallButton}>
                                                                                {list.buttonTitle}
                                                                            </Grid>
                                                                            <Grid item style={{ display: 'flex' }}>
                                                                                <RightArrow color='primary' style={{ fontSize: '1rem' }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </LinkButton>
                                                                </Grid>
                                                            </Grid>

                                                        </div>
                                                    </Grid>
                                                }
                                                {
                                                    list.showButton2 &&
                                                    <Grid item xs={12}>
                                                        <div>
                                                            <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
                                                                <Grid item style={{ display: 'flex', visibility: 'hidden' }}>
                                                                    <RightIcon color='primary' style={{ fontSize: '1rem' }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <LinkButton href={list.buttonRoute2} target={"_blank"}>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item className={classes.linksSmallButton}>
                                                                                {list.buttonTitle2}
                                                                            </Grid>
                                                                            <Grid item style={{ display: 'flex' }}>
                                                                                <RightArrow color='primary' style={{ fontSize: '1rem' }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </LinkButton>
                                                                </Grid>
                                                            </Grid>

                                                        </div>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>
                                    </Grid>
                                })
                            }
                            <Grid item container alignItems='flex-start' justifyContent='flex-end' xs={1}>
                                <IconButtonComponent onClick={popupState.close}>
                                    <CloseIcon />
                                </IconButtonComponent>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </MenuComponent>
        </>
    )
}
