import React from 'react'
import { Hidden, makeStyles } from '@material-ui/core'
import SectionTitleComponent from '../../SectionTitleComponent'
// import Form from './Form'
import { contactFormId } from '../../../utilities/Configurations'

const useStyles = makeStyles(theme => ({
    webContainer: {
        width: '100%',
        padding: "0px",
        display: 'flex', justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up("sm")]: {
            padding: "3rem 0px 0px",
            paddingTop: '0px',
        }
    },
    webCard: {
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.03)",
        backgroundColor: 'white',
        padding: "0px",
        width: '100%',
        // [theme.breakpoints.up("sm")]: {
        //     padding: "3rem",
        // }
    }
}))

export default function FormComponent({ title = 'Get an Estimate from our team' }) {
    const classes = useStyles()

    React.useEffect(() => {
        const loadForm = () => {
            if ('hbspt' in window) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "26733695",
                    formId: "391e6fa0-34c5-4c4b-bc47-57d9f5910b03",
                    target: `#contact-form-id`
                })
            } else {
                setTimeout(loadForm, 500)
            }
        }
        loadForm()

    }, [])
    return (
        <div className={classes.container}>
            <SectionTitleComponent
                sectionId={contactFormId}
                title={title}
            >
                <div className={classes.webContainer}>
                    <div className={classes.webCard}>
                        {/* <Form /> */}
                        <div id="contact-form-id"></div>
                    </div>
                </div>
            </SectionTitleComponent>
        </div>
    )
}
