import React from 'react'
import Routes from './Routes'
import Facebook from '../components/SVGs/Facebook'
import LinkedIn from '../components/SVGs/LinkedIn'
import Twitter from '../components/SVGs/Twitter'
import Instagram from '../components/SVGs/Instagram'
const palettePrimaryColors = {
    light: '#6f97ff',
    main: "#2a6ae2",
    dark: "#0040af"
}

const paletteSecondaryColors = {
    light: "#51657c",
    main: '#263b50',
    dark: "#001528"
}

const primaryTextColors = '#526373'

const secondaryTextColors = '#2a6ae2'

export const paletteConfig = {
    primary: palettePrimaryColors,
    secondary: paletteSecondaryColors,
    text: {
        primary: primaryTextColors,
        secondary: secondaryTextColors
    }
}


export const linkedinLink = { id: 'linkedin', bg: "#0077B5", slug: "https://www.linkedin.com/company/blackrookconsulting", image: <LinkedIn />, alt: "LinkedIn" }
export const facebookLink = { id: 'facebook', bg: "#3B5998", slug: 'https://www.facebook.com/blackrookconsulting', image: <Facebook />, alt: "Facebook" }
export const twitterLink = { id: 'twitter', bg: "#1DA1F2", slug: 'https://twitter.com/BlackRookGlobal', image: <Twitter />, alt: 'Twitter' }
export const instagramLink = { id: "instagram", bg: "#E54672", slug: 'https://www.instagram.com/blackrookconsulting/', image: <Instagram />, alt: "Instagram" }
export const socialLinks = [linkedinLink, facebookLink, twitterLink, instagramLink]

export const primaryButtonSVGbase64Url = "data:image/svg+xml,%3Csvg width='150' height='40' viewBox='0 0 150 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M150 0.0144492L15.2114 0L5.0051e-05 14.5337L0 39.9855L150 40L150 0.0144492Z' fill='%232A6AE2'/%3E%3C/svg%3E%0A"
export const primaryButtonHoverSVGbase64Url = "data:image/svg+xml,%3Csvg width='150' height='40' viewBox='0 0 150 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M150 0.0144492L15.2114 0L5.0051e-05 14.5337L0 39.9855L150 40L150 0.0144492Z' fill='%23154FBB'/%3E%3C/svg%3E "
export const secondaryButtonSVGbase64Url = "data:image/svg+xml,%3Csvg width='152' height='42' viewBox='0 0 152 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M150.994 1.01445L16.2053 1L0.993947 15.5337L0.993896 40.9855L150.994 41L150.994 1.01445Z' stroke='%232A6AE2'/%3E%3C/svg%3E"
export const secondaryButtonHoverSVGbase64Url = "data:image/svg+xml,%3Csvg width='152' height='42' viewBox='0 0 152 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M151 1.01445L16.2114 1L1.00005 15.5337L1 40.9855L151 41L151 1.01445Z' fill='%23DBEEFF' stroke='%23134DBA'/%3E%3C/svg%3E "
export const clientBorderSVGbase64Url = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQ4IiBoZWlnaHQ9IjEyMyIgdmlld0JveD0iMCAwIDM0OCAxMjMiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMzQ3LjA2OSAxMjIuMTg3SDAuODQxNzk3VjM1LjA2OTNMMzkuMzExNSAxLjQxMDE2SDM0Ny4wNjlWMTIyLjE4N1oiIGZpbGw9IndoaXRlIiBzdHJva2U9IiNFMkUyRTIiLz4NCjwvc3ZnPg0K'

export const contactFormId = 'contact-form'